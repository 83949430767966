
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
            
			<!-- {{id}} -->
			<!-- {{salary}} -->
			<!-- <form @submit.prevent="updateSalary" autocomplete="off">
                <v-layout row wrap>
        
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable  :items="employees" v-model="salary.employee_id" dense  filled outlined  item-text="employee_id"
                                    item-value="employee_id" :return-object="false" :label="$store.getters.language.data.employees.employee_id">
                        </v-select>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable  :items="salary_list" v-model="salary.salary_list_id" dense  filled outlined  item-text="salary_list_id"
                                    item-value="salary_list_id" :return-object="false" :label="$store.getters.language.data.salary_list.salary_list_id">
                        </v-select>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="salary.salary_base" type="text" :label="$store.getters.language.data.salary.salary_base" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="salary.salary_total" type="text" :label="$store.getters.language.data.salary.salary_total" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="salary.salary_gov_inss" type="text" :label="$store.getters.language.data.salary.salary_gov_inss" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="salary.salary_fines_total" type="text" :label="$store.getters.language.data.salary.salary_fines_total" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="salary.salary_iligal_days_amount" type="number" :label="$store.getters.language.data.salary.salary_iligal_days_amount" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="salary.salary_iligal_days_total" type="text" :label="$store.getters.language.data.salary.salary_iligal_days_total" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="salary.salary_extra_hours_amount" type="text" :label="$store.getters.language.data.salary.salary_extra_hours_amount" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="salary.salary_extra_hours_total" type="text" :label="$store.getters.language.data.salary.salary_extra_hours_total" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="salary.salary_total_commission" type="text" :label="$store.getters.language.data.salary.salary_total_commission" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="salary.salary_inss_amount" type="text" :label="$store.getters.language.data.salary.salary_inss_amount" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="salary.salary_insert_date" type="datetime-local" :label="$store.getters.language.data.salary.salary_insert_date" 
                            class="mx-1"  filled outlined dense  >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn  color="success"    type="submit">{{$store.getters.language.data.salary.update_btn}}</v-btn>
                    </v-flex>
                </v-layout>
            </form> -->
			<v-layout row wrap v-if="salary && this.$store.getters.salary_report_list[0].salary_report_status ==1">

				<v-flex xs12 lg12 xl12 md12 sm4>
					<!-- <h3>employee_id: {{salary.employee_id}}</h3> -->
					<h1>
                        Name: {{salary.employee_name}}
                        </h1>
					<h3>
						Base Salary: {{(salary.salary_base).toLocaleString()}}
					</h3>

					<h3>
						Food Budget: {{(salary.employee_food_budget).toLocaleString()}}
					</h3>

					<h3>
						Transportation Budget: {{(salary.employee_transportation_budget).toLocaleString()}}
					</h3>
					<h3 v-if="salary.employee_phone_budget > 0">
						Phone Budget: {{(salary.employee_phone_budget).toLocaleString()}}
					</h3>
					<h3 v-if="salary.employee_driver_budget > 0">
						Driver Budget: {{(salary.employee_driver_budget).toLocaleString()}}
					</h3>
                    <h3 >
                        Overtime Hours : {{salary.salary_extra_hours_amount}}  Hours<span  v-if="salary.salary_extra_hours_total > 0"
                        style="color:green"> -> +{{(salary.salary_extra_hours_total).toLocaleString()}}</span>
                    </h3>
					<h3>
						Government Insurance: -{{(salary.salary_gov_inss).toLocaleString()}}
					</h3>
					<h3>
						Company Insurance: -{{(salary.salary_inss_amount).toLocaleString()}}
					</h3>
					<h3>
						Total Fines: -{{(salary.salary_fines_total).toLocaleString()}}
					</h3>
					<h3>
						Total Salary: {{(salary.salary_total+salary.salary_iligal_days_total).toLocaleString()}}
					</h3>
					<p>(Without Iligal Days)</p>
					<v-divider class="my-4"></v-divider>

				</v-flex>
				<v-flex xs12 lg12 xl12 md12 sm4>
                    <h3>
                        Iligal Days: {{salary.salary_iligal_days_amount}} Days<span v-if="salary.salary_iligal_days_total > 0"
                        style="color:red"> -> -{{(salary.salary_iligal_days_total).toLocaleString()}}</span>
                    </h3>
                    <!-- <h3>
                        Fines: -{{(salary.salary_fines_total).toLocaleString()}}
                    </h3> -->
                    <h2>
                        Amount to be paid:
                        IQD {{(salary.salary_total).toLocaleString()}}
                    </h2>

                   <p>Insurance Balance : {{insurance[0].final_amount}}</p>
                    <v-divider class="my-4"></v-divider>
				</v-flex>
			</v-layout>
            <v-layout row wrap v-else>
              <!-- your salary is not ready yet please contact the Human Resources Department -->
                <v-flex xs12 lg12 xl12 md12 sm4>
                    <v-alert
                    :value="true"
                    type="error"
                    transition="scale-transition"
                    dismissible
                    >
                    <v-layout row wrap>
                        <v-flex xs12 lg12 xl12 md12 sm4>
                        <h3>
                            Your salary is not ready yet please contact the Human Resources Department
                        </h3>
                        </v-flex>
                    </v-layout>
                    </v-alert>
                </v-flex>
            </v-layout>
		</v-container>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from '../../requests/salary.request.js'
	export default {
		data() {
			return {
				salary: {},
                insurance: {},
				id: '',
				loading: false,
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
			}
		},
		computed: {


		},
		mounted() {
			this.id = this.$store.getters.user.employee_id
			this.getOneSalary()
		},
		methods: {
			getOneSalary() {
				// this.loading = true
				let query = {
					employee_id: this.id,
					salary_list_id: this.$store.getters.salary_report_list[0].salary_list_id
				}
				requests.getSalaryReport(query).then(r => {
					if (r.status == 200) {
						console.log(r.data)
						this.salary = r.data.row[0]
                        this.insurance = r.data.insurance_totals
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Salary',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Salary',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			updateSalary() {
				this.loading = true
				requests.updateSalary(this.id, this.salary).then(r => {
					if (r.status == 200) {
						this.snackbar = {
							value: true,
							text: 'Salary Updated',
							color: 'success'
						}
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Update Faild',
							color: 'error'
						}
						this.loading = false
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Update Faild',
							color: 'error'
						}
						this.loading = false
					})
					.finally(() => {
						this.loading = false
					})
			},
		},
	}
</script>
                    